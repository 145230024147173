import { Button, Heading, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import RequestForm from 'components/forms/RequestForm';
import React from 'react';
import { BsBookmarkCheckFill } from 'react-icons/bs';
import { FaRegComment } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { useTranslation } from 'hooks';

const Register = ({ setRegister, backUrl }) => {
  const { t } = useTranslation();

  return (
    <>
      <VStack align={'start'} spacing='10' textAlign='center' pb='4'>
        <Heading as='h3' fontSize={'h3'}>
          {' '}
          {t('general.forms.join_family')}{' '}
        </Heading>

        <VStack align={'stretch'} spacing={4}>
          <HStack spacing={3}>
            <Icon as={BsBookmarkCheckFill} fontSize='24px' />
            <Text color='dark.600' fontSize={'16px'}>
              {t('general.forms.save_favorite_articles')}
            </Text>
          </HStack>

          <HStack spacing={3}>
            <Icon as={FaRegComment} fontSize='24px' />
            <Text color='dark.600' fontSize={'16px'}>
              {t('general.forms.express_opinion')}{' '}
            </Text>
          </HStack>
          <HStack spacing={3}>
            <Icon as={MdEmail} fontSize='24px' />
            <Text color='dark.600'>
              {t('general.forms.register_newsletter')}{' '}
            </Text>
          </HStack>
        </VStack>
      </VStack>

      <RequestForm
        type='register'
        btnText={t('general.forms.register_now')}
        btnProps={{
          colorScheme: 'dark'
        }}
        setRegister={setRegister}
        backUrl={backUrl}
      >
        <Text color='dark.600' fontSize={'16px'}>
          {t('general.forms.have_account')}{' '}
          <Button
            colorScheme='linkedin'
            variant={'link'}
            onClick={() => setRegister(false)}
          >
            {t('general.forms.login')}
          </Button>
        </Text>
      </RequestForm>
    </>
  );
};

export default Register;

import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  Text,
  HStack,
  Box
} from '@chakra-ui/react';
import { LogoSvg } from 'assets';
import {
  FacebookAuthButton,
  GoogleAuthButton
} from 'components/forms-elements';
import Login from './Login';
import Register from './Register';
import { hideLoginModal, useLayoutContext } from 'context/layout';
import { useRouter } from 'next/router';
import { useTranslation } from 'hooks';
const LoginModal = ({}) => {
  const [register, setRegister] = useState(false);
  const { dispatch, state } = useLayoutContext();
  const { locale, t } = useTranslation();
  const isEnglish = locale === 'en';
  const router = useRouter();

  return (
    <Modal
      isOpen={state.loginModalShow}
      onClose={() => dispatch(hideLoginModal())}
    >
      <ModalOverlay />
      <ModalContent
        paddingInlineStart={'0rem !important'}
        paddingInlineEnd={'0rem !important'}
        w={{ base: '96%', md: '100%' }}
      >
        <ModalHeader></ModalHeader>
        <ModalCloseButton insetInlineEnd={isEnglish ? '90%' : '3'} />
        <ModalBody py='2' px='0'>
          <VStack
            align={'center'}
            borderBottomWidth='1px'
            borderColor={'black'}
            pb='6'
          >
            <LogoSvg w={44} />
          </VStack>
          <VStack align='stretch' spacing={4} p='10'>
            {register ? (
              <Register
                register={register}
                setRegister={setRegister}
                backUrl={router.asPath}
              />
            ) : (
              <Login
                register={register}
                setRegister={setRegister}
                backUrl={router.asPath}
              />
            )}

            <HStack
              spacing={6}
              align='center'
              justifyContent={'center'}
              alignItems='center'
              pt='2'
            >
              <Box width={'40px'} height='2px' bg={'dark.300'} />
              <Text> {t('general.forms.or')} </Text>
              <Box width={'40px'} height='2px' bg={'dark.300'} />
            </HStack>

            <VStack align={'center'} spacing='6' pt='2'>
              <Text>{t('general.forms.log_in_with')}</Text>
              <HStack spacing={6}>
                <GoogleAuthButton
                  title={t('general.forms.google')}
                  type={'register'}
                />
                <FacebookAuthButton
                  title={t('general.forms.facebook')}
                  type={'register'}
                />
              </HStack>
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;

import { Button, Heading, Text, VStack } from '@chakra-ui/react';
import RequestForm from 'components/forms/RequestForm';
import { useTranslation } from 'hooks';
import React from 'react';

const Login = ({ setRegister, backUrl }) => {
  const { t } = useTranslation();

  return (
    <>
      <VStack align={'start'} spacing='2' textAlign='center' pb='4'>
        <Heading as='h3' fontSize={'h3'}>
          {t('general.forms.login')}
        </Heading>
        <Text color='dark.600' display={'flex'} gap={'5px'} fontSize={'16px'}>
          {t('general.forms.dont_have_account')}
          <Button
            colorScheme='linkedin'
            variant={'link'}
            onClick={() => setRegister(true)}
          >
            {t('general.forms.register_now')}
          </Button>
        </Text>
      </VStack>

      <RequestForm
        type='login'
        btnText={t('general.forms.login')}
        btnProps={{
          colorScheme: 'dark'
        }}
        setRegister={setRegister}
        backUrl={backUrl}
      ></RequestForm>
    </>
  );
};

export default Login;
